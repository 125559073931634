exports.env = function () {
  return {
    // 开发环境变量
    // 修改BASE_API需要重跑
    development: {
      BASE_API: 'https://wkjc.hnsyec.com/senyuan',
      IMAGE_PREFIX: 'https://wkjc.hnsyec.com/images/',
      WEBSOCKET: 'wss://wkjc.hnsyec.com/senyuan/websocket',

      // BASE_API: 'http://192.168.99.182:25000/senyuan',
      // IMAGE_PREFIX: 'https://wkjc.hnsyec.com/images/',
      // WEBSOCKET: 'wss://wkjc.hnsyec.com/senyuan/websocket',

      // BASE_API: 'https://senyuantest.yuankonginfo.com',
      // BASE_API: 'http://192.168.99.165:25000/senyuan',
      // BASE_API: 'http://192.168.99.183:25000/senyuan',
      // BASE_API: 'https://wkjc.hnsyec.com/senyuan',
      // BASE_API: 'https://wkjc.hnsyec.com/senyuan',
      // IMAGE_PREFIX: 'https://sfile.yuankonginfo.com/',
      // WEBSOCKET: 'wss://rc.yuankonginfo.com/wss/socket'

    },
    // 生产环境变量
    production: {
      BASE_API: 'https://wkjc.hnsyec.com/senyuan',
      // BASE_API: 'http://139.196.242.248:8081',
      IMAGE_PREFIX: 'https://wkjc.hnsyec.com/images/',
      WEBSOCKET: 'wss://wkjc.hnsyec.com/senyuan/websocket',
      // WEBSOCKET: 'wss://rc.yuankonginfo.com/wss/socket'
    }
  }
}
