import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/device', component: Layout, redirect: '/device/list/0/realtime',
    meta: { title: 'device', icon: 'icon-caidanlanshebeiliebiao' },
    children: [
      {
        path: 'list/:did', name: 'DeviceList', redirect: '/device/list/0/realtime',
        meta: { title: 'deviceList', icon: 'icon-caidanlanmobanguanli1' },
        component: () => import('@/views/device/dataShow/DeviceList.vue'),
        children: [
          {
            path: 'realtime', name: 'DeviceRealTimeData',
            meta: { title: 'deviceList', icon: 'icon-caidanlanmobanguanli1', activeMenu: 'DeviceList' },
            component: () => import('@views/device/dataShow/realtimeData/RealTimeData.vue'),
          },
          {
            path: 'alarm-record', name: 'DeviceAlarmRecord',
            meta: { title: 'deviceList', icon: 'icon-caidanlanmobanguanli1', activeMenu: 'DeviceList' },
            component: () => import('@/views/device/dataShow/AlarmRecord.vue'),
          },
          {
            path: 'alarm-count', name: 'DeviceAlarmCount',
            meta: { title: 'deviceList', icon: 'icon-caidanlanmobanguanli1', activeMenu: 'DeviceList' },
            component: () => import('@/views/device/dataShow/AlarmCount.vue'),
          },
        ],
      },
      // {
      //   path: 'list2/:did', name: 'DeviceList2',
      //   meta: { title: 'deviceList', icon: 'icon-caidanlanmobanguanli1' },
      //   component: () => import('@/views/device/dataShow/DeviceList2.vue'),
      //   children: [
      // {
      //   path: 'realTimeData', name: 'DeviceRealTimeData',
      //   meta: { title: '实时数据', menu: false },
      //   component: () => import('@/views/device/dataShow/RealTimeData.vue'),
      // },
      // {
      //   path: 'alarmRecord', name: 'DeviceAlarmRecord',
      //   component: () => import('@/views/device/dataShow/AlarmRecord.vue'),
      // },
      // {
      //   path: 'alarmCount', name: 'DeviceAlarmCount',
      //   component: () => import('@/views/device/dataShow/AlarmCount.vue'),
      // },
      // {
      //   path: 'list/share', name: 'ShareDevice',
      //   meta: { title: 'shareDevice', icon: 'icon-caidanlanshebeiguanli' },
      //   component: () => import('@/views/device/dataShow/ShareDevice.vue'),
      // },
      //   ],
      // },
      {
        path: 'manage', name: 'DeviceManage',
        meta: { title: 'deviceManage', icon: 'icon-caidanlanshebeiguanli' },
        component: () => import('@/views/device/DeviceManage.vue'),
        children: [
          {
            path: 'add', name: 'AddDevice',
            meta: { title: 'addDevice', icon: 'icon-caidanlanshebeiguanli' },
            component: () => import('@/views/device/AddDevice/index.vue'),
          },
        ]
      },
      {
        path: 'model', name: 'DeviceModelManage',
        meta: { title: 'deviceModelManage', icon: 'icon-caidanlanmoxingguanli', role: ['ROLE_admin', 'ROLE_supperUser'] },
        component: () => import('@/views/device/DeviceModelManage.vue'),
      },
      // {
      //   path: 'variable', name: 'VariableModelManage',
      //   meta: { title: 'variableModelManage', icon: 'icon-caidanlanmobanguanli1' },
      //   component: () => import('@/views/variable/VariableModelManage.vue'),
      // },
    ]
  },
]
export default { routes, menu: true }
