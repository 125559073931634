import { RouteRecordRaw } from 'vue-router'

const modulesFiles = require.context('../modules', true, /\.ts$/)
// 菜单路由
let menuModules: RouteRecordRaw[] = []
// 其他路由
const otherModules: RouteRecordRaw[] = []
// 排序规则
const menuOrder = ['board', 'device', 'alarm', 'report', 'inside', 'other']
// 排序路由存放
const orderModules: RouteRecordRaw[][] = []
modulesFiles.keys().forEach((modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  if (moduleName !== 'index') {
    if (value.default.menu) {
      const index = menuOrder.indexOf(moduleName)
      if (index > -1) {
        orderModules[index] = value.default.routes
      } else {
        menuModules.push(...value.default.routes)
      }
    } else {
      otherModules.push(...value.default.routes)
    }
  }
})

// 合并排序的路由
const tempModules: RouteRecordRaw[] = []
orderModules.forEach((routes) => {
  tempModules.push(...routes)
})
menuModules = tempModules.concat(menuModules)

export { menuModules, otherModules }
