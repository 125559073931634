import request from './request'
import store from '@/store'
import { getUserInfoAPI } from '@/api/user'
import { storage } from '@/utils/storage'
import { juageAuthorityAPI } from '@/api/alarm'
import router from '@/router'
import { ObjectUtil } from '@utils/object-util'
import settings from '@/settings'
import { User } from '@typings/user'

/**
 * 获取 Token
 */
// export function getToken (): string | null {
//   return localStorage.getItem('tk')
// }

/**
 * 设置 Token
 * @param token
 */
// export function setToken (token: string): void {
//   request.defaults.headers.common['YK'] = token
//   localStorage.setItem('tk', token)
// }

/**
 * 清除 Token
 */
// export function removeToken (): void {
//   storage.cookie.remove('_at')
//   localStorage.clear()
//   sessionStorage.clear()
//   delete request.defaults.headers.common['YK']
// }

/**
 * 检查设备授权
 * ['可管理','允许控制', '仅查看', ]
 */
export function checkDeviceAuth (deviceId: number): Promise<number> {
  return new Promise(function (resolve, reject) {
    // 获取缓存的权限
    const cacheAuthority = store.getters.deviceAuthority[deviceId]
    // 如果可以获取到缓存的权限且没有超过5分钟
    if (cacheAuthority && new Date().getTime() < cacheAuthority.updateTime + 300000) {
      // 使用缓存的权限
      resolve(cacheAuthority.authority)
    } else {
      // 否则重新获取权限
      juageAuthorityAPI({ deviceId: deviceId }).then(res => {
        res.result().then((rdata) => {
          // 获取权限
          const authority = rdata.payload.data?.userDeviceAuthority
          if (ObjectUtil.isNotEmpty(authority)) {
            // 存储并返回权限
            store.commit('setDeviceAuthority', { deviceId, authority })
          } else {
            router.push('/error/403')
          }
          resolve(authority)
        })
      }).catch(reject)
    }
  })
}
/**
 * 获取 Token
 */
export function getToken (): string {
  return localStorage.getItem('ato')
}

/**
 * 设置 Token
 * @param token
 */
export function setToken (token: string): void {
  request.defaults.headers.common[settings.HEADERS_TOKEN_KEY] = token
  localStorage.setItem('ato', token)
}

/**
 * 清除 Token
 */
export function removeToken (): void {
  storage.cookie.remove('_at')
  localStorage.clear()
  sessionStorage.clear()
  // store.commit('setUserInfo', {})
  delete request.defaults.headers.common[settings.HEADERS_TOKEN_KEY]
}
// export function removeToken (): void {
//   storage.cookie.remove('_at')
//   localStorage.clear()
//   sessionStorage.clear()
//   delete request.defaults.headers.common['YK']
// }

/**
 * 授权验证
 * @param itemPermission
 * @param itemRole
 */
export function authorizationCheck (itemPermission = '' as string | string[], itemRole: string[] = []): boolean {
  if (!itemPermission && !itemRole.length) return true
  // const user: User = store.getters['app/userInfo']
  const user: User = store.getters.userInfo
  if (!user.id) return false
  if (user.role === settings.ADMINISTRATOR_ROLE_ID) return true
  if (itemRole.indexOf(user.role) > -1) return true
  if (Array.isArray(itemPermission)) {
    for (let i = 0, len = itemPermission.length; i < len; i++) {
      const item = itemPermission[i].toString()
      if (user.permission.has(item)) return true
    }
    return false
  } else {
    return user.permission.has(itemPermission.toString())
  }
}

/**
 * 更新用户信息
 */
export function updateUserInfo (): Promise<unknown> {
  return new Promise((resolve, reject) => {
    if (getToken()) {
      getUserInfoAPI().then(res => {
        res.result().then(rdata => {
          // sessionStorage.setItem('token', res.data.payload.token)
          store.commit('setUserInfo', rdata.payload.data)
          resolve(undefined)
        })
      }).catch(reject)
    }
  })
}

/**
 * 保存图片
 * @param canvas
 * @param filename
 */
export function downCanvasFile (canvas: HTMLCanvasElement, filename: string | number): void {
  const link = document.createElement('a')
  link.href = canvas.toDataURL('image/png')
  link.download = filename + '.png'
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
}
