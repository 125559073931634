import interceptorConfig from './config'
import { getToken, updateUserInfo } from '@/utils/auth'
import { RouteLocationNormalized } from 'vue-router'
import store from '@/store'

interface AuthResult { state: boolean, toPath?: string }

/**
 * 验证授权
 * @param to
 */
export const auth = async (to: RouteLocationNormalized): Promise<AuthResult> => {
  let config = interceptorConfig.default
  const authResult: AuthResult = { state: true }
  for (const key in interceptorConfig) {
    if (new RegExp(key.replace(/\*/g, '.*')).test(to.path)) {
      config = interceptorConfig[key]
      break
    }
  }
  const token = getToken()
  // 如果页面需要token且没有
  if (config.token && !token) {
    authResult.state = false
    authResult.toPath = config.notTokenTo
  } else if (token && config.tokenTo) { // 如果页面不需要token且有token和需要跳转的地址
    authResult.state = false
    authResult.toPath = config.tokenTo
  }
  if (config.token && !store.getters.userInfo.id) {
    await updateUserInfo()
  }
  return authResult
}

export const role = (to: RouteLocationNormalized) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise((resolve, reject) => {
    if (to.meta.role) {
      // if (!store.getters.userInfo.id) {
      //
      //   // resolve(store.getters.userInfo.role === to.meta.role)
      // }
      resolve(to.meta.role.includes(store.getters.userInfo.role))
    } else {
      resolve(true)
    }
  })
}
