import { Directive } from 'vue'
import { authorizationCheck } from '@utils/auth'

// 点击空白处触发指令
const directive: Directive = {
  mounted (el, binding) {
    const [itemPermission = '', itemRole = []] = binding.value
    const authResult = authorizationCheck(itemPermission, itemRole.map(item => item.toString()))
    if (!authResult) {
      el.remove()
    }
  }
}

export default directive
