import router from '@/router'
import nProgress from 'nprogress'
import 'nprogress/nprogress.css'
import settings from '@/settings'
import { auth, role } from '@/router/interceptor'
import store from '@/store'

// 添加路由前置守卫
router.beforeEach(async (to, from, next) => {
  nProgress.start()
  // window.loadingBox.show()
  // 身份验证
  const authResult = await auth(to)
  if (authResult.state) {
    // 权限验证
    role(to).then(res => {
      if (res) {
        if (to.path === '/system') {
          const user = store.getters.userInfo
          switch (user.role) {
            case 'ROLE_admin':
            case 'ROLE_supperUser':
              next('/system/customer/manage')
              break
            case 'ROLE_userControl':
              next('/system/user/manage')
              break
            case 'ROLE_user':
              next('/system/log/system')
              break
          }
        } else {
          next()
        }
      } else {
        next('/error/403')
      }
    })
    const { title } = to.meta
    document.title = `${title ? store.getters.languageJSON.menu[title] + ' - ' : ''}${settings.TITLE}`
  } else {
    // window.loadingBox.hide()
    nProgress.done()
    authResult.toPath && next(authResult.toPath)
  }
})

// 添加路由后置守卫
router.afterEach((to, from) => {
  // window.loadingBox.hide()
  nProgress.done()
})
