import request from '@/utils/request'
import { Pages } from '@/typings'
import { AlarmRules } from '@/typings/alarm'
import qs from 'qs'

/**
 * 获取报警列表
 * @param params
 */
type getAlarmAPIParams = {
  deviceId?: string, beginDate: string, endDate: string,
  alarmState?: number|string // 1:操作日志; 2:设备上下线日志
}
export const getAlarmAPI = (params: getAlarmAPIParams | getAlarmAPIParams & Pages) => request.get('/alarm/getAlarmByDeviceId', { params })
/**
 * 获取报警次数
 * @param params
 */
export const getAlarmCountAPI = () => request.get('/alarm/getAlarmPoliceStatistics')

/**
 * 获取报警规则
 * @param params
 */
export const getAlarmRulesAPI = (params: { deviceId: string | number } & Pages) => request.get('/alarmRules/findAlarmRulesByMsg', { params })
/**
* 获取变量列表
* @param params
*/
export const getVariableAPI = (params: { deviceId: string | number }) => request.get('/real/getRealData', { params })
/**
 * 添加或者修改报警规则
 * @param params
 */
export const saveOrUpdateAPI = (params: AlarmRules) => request.post('/alarmRules/editAlarmRules', params)

/**
 * 设备模型 - 更新报警规则
 * @param params
 */
export const updateAlarmRuleAPI = (params: { alarmRules: AlarmRules, variableId: number }) => request.post('/alarmRules/updateAlarm', params.alarmRules, {
  params: {
    variableId: params.variableId
  },
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [function (data) { return JSON.stringify(data) }]
})

/**
 * 获取人员对设备的权限
 * @param params
 */
export const juageAuthorityAPI = (params: { deviceId: string | number }) => request.get('/web/device/getDeviceAuthority', { params })
/**
 * 删除报警规则
 * @param params
 */
export const deleteAlarmRulesAPI = (params: { alarmRulesId: string | number; deviceId: string }) => request.post('/alarmRules/deleteAlarmRules', params)
/**
 * 获取历史数据
 * @param params
 */
export const getAlarmHistoryDataAPI = (params: { deviceId: string | number, variableId: string | number, beginTime: string, endTime: string }) => request.get('/history/getHistoryData', { params })
/**
 * 获取变量规则列表
 * @param params
 */
export const getVariableRuleAPI = (params: { deviceId: string | number }) => request.get('/variable/findVariableAndTemplateByDeviceId', { params })
