import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/alarm', component: Layout, redirect: '/alarm/record',
    meta: { title: 'alarm', icon: 'icon-caidanlanbaojingxinxi-1' },
    children: [
      {
        path: 'record', name: 'AlarmRecord',
        meta: { title: 'alarmRecord', icon: 'icon-caidanlanlishibaojing-1' },
        component: () => import('@views/alarm/alarmRecord/AlarmRecord.vue'),
      },
      {
        path: 'rules', name: 'AlarmRules',
        meta: { title: 'alarmRules', icon: 'icon-caidanlanbaojingguize' },
        component: () => import('@/views/alarm/AlarmRules.vue'),
      },
      // {
      //   path: 'setting', name: 'AlarmSetting', redirect: '/alarm/setting/notice-manage',
      //   meta: { title: 'alarmSetting', icon: 'icon-weixintongzhi' },
      //   component: () => import('@/views/alarm/alarmSetting/index.vue'),
      //   children: [
      //     {
      //       path: 'notice-manage', name: 'MessageNoticeManage',
      //       meta: { title: 'messageNoticeManage', icon: 'icon-xiaoxitongzhi' },
      //       component: () => import('@/views/alarm/alarmSetting/MessageNoticeManage.vue'),
      //     },
      //     {
      //       path: 'send-record', name: 'MessageSendRecord',
      //       meta: { title: 'messageSendRecord', icon: 'icon-caidanlanbaojingguize' },
      //       component: () => import('@/views/alarm/alarmSetting/MessageSendRecord.vue'),
      //     },
      //   ]
      // }
    ]
  },
]
export default { routes, menu: true }
