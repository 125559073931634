import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/system', component: Layout,
    meta: { title: 'system', icon: 'icon-neibu' },
    children: [
      {
        path: 'customer/manage', name: 'CustomerManagement',
        meta: { title: 'customerManage', icon: 'icon-caidanlanshebeiguanli', role: ['ROLE_admin', 'ROLE_supperUser'] },
        component: () => import('@/views/inside/CustomerManagement.vue'),
      },
      {
        path: 'contract/manage', name: 'ContractManagement',
        meta: { title: 'contractManagement', icon: 'icon-caidanlanshebeiguanli', role: ['ROLE_admin', 'ROLE_supperUser'] },
        component: () => import('@/views/inside/ContractManagement.vue'),
      },
      {
        path: 'assets/manage', name: 'AssetsManage',
        meta: { title: 'assetsManage', icon: 'icon-caidanlanzichanguanli', role: ['ROLE_admin', 'ROLE_supperUser'] },
        component: () => import('@/views/inside/AssetsManage.vue'),
      },
      {
        path: 'user/manage', name: 'UserManage',
        meta: { title: 'userManage', icon: 'icon-caidanlankehuguanli', role: ['ROLE_admin', 'ROLE_supperUser', 'ROLE_userControl'] },
        component: () => import('@/views/inside/UserManage.vue'),
      },
      {
        path: 'log/system', name: 'SystemLog',
        meta: { title: 'systemLog', icon: 'icon-caidanlanxitongrizhi' },
        component: () => import('@/views/other/SystemLog.vue'),
      },
      {
        path: 'log/device', name: 'DeviceLog',
        meta: { title: 'deviceLog', icon: 'icon-caidanlanshebeirizhi' },
        component: () => import('@/views/other/DeviceLog.vue'),
      }
      // {
      //   path: 'invoice/audited', name: 'AuditedInvoices',
      //   meta: { title: 'auditedInvoices', icon: 'icon-caidanlanfapiaoshenhe' },
      //   component: () => import('@/views/inside/AuditedInvoices/index.vue'),
      // }
    ]
  }
]
export default { routes, menu: true }
