import moment from 'moment'
moment.locale('zh-cn')

/* eslint-disable */
export enum DateFormat {
  time = 'HH:mm:ss',
  date = 'YYYY-MM-DD',
  datetime = 'YYYY-MM-DD HH:mm:ss'
}
/* eslint-disable */

/**
 * 获取近几天
 * @param day
 */
export function recentDate (day: number): string[] {
  const today = moment()
  const before = moment().add(-day, 'days')
  return [before.format(DateFormat.date), today.format(DateFormat.date)]
}
// date 代表指定的日期，格式：2018-09-27
// day 传-1表始前一天，传1表始后一天
// JS获取指定日期的前一天，后一天
export function getNextDate(date, day) {
  const dd = new Date(date);
  dd.setDate(dd.getDate() + day);
  const y = dd.getFullYear();
  const m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
  const d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
  return y + "-" + m + "-" + d;
}

export default moment
