import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/board', component: Layout, redirect: '/board/workbench',
    meta: { title: 'board', icon: 'icon-kanban' },
    children: [
      {
        path: 'workbench', name: 'Workbench',
        meta: { title: 'workbench', icon: 'icon-caidanlanshouye' },
        component: () => import('@/views/board/cockpit/index.vue'),
      },
    ]
  },
]
export default { routes, menu: true }
