import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/test', name: 'TestParent',
  //   component: () => import('../../views/test/TestParent.vue')
  // },
  // {
  //   path: '/theme', name: 'Theme',
  //   component: () => import('@/views/theme/index.vue')
  // },
]
export default { routes }
