import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { menuModules, otherModules } from './modules'
declare module 'vue-router' {
  interface RouteMeta {
    title?: string, icon?: string, activeMenu?: string, role?: Array<string>, permission?: string, isMenu?: boolean
  }
}
// /* webpackChunkName: "ChunkName" */
// 菜单路由
// export const menuRoutes: Array<RouteRecordRaw> = [
//   ...menuModules
// ]

const routes: Array<RouteRecordRaw> = [
  ...menuModules, ...otherModules
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
