/* eslint-disable */
declare type forEachCallback = (value: unknown, key: string) => void
/**
 * @param deep : boolean 仅合并现有属性
 * @param object : boolean 是否对object类型深合并
 * @param array : boolean 是否对array类型深合并
 */
declare type assignDeepOption = { deep?: boolean, object?: boolean, array?: boolean }

export class ObjectUtil {
  /**
   * 根据地址获取源数据中的值
   * @param source
   * @param address
   */
  static getAttr(source: undefined | any, address: string): any {
    const tier = address.split('.')
    try {
      tier.forEach(key => {
        source = source[key]
      })
      return source
    } catch (e) {
      return undefined
    }
  }

  /**
   * 遍历对象或数组
   * @param source
   * @param callback
   */
  static forEach(source: Record<string, unknown>, callback: forEachCallback): void {
    for (const key in source) {
      callback(source[key], key)
    }
  }

  /**
   * 判断参数是否为null|undefined|''
   * @param value
   */
  static isNotEmpty(value: unknown): boolean {
    return value === 0 || !!value
  }

  /**
   * 判断参数是否为null|undefined|''
   * @param value
   */
  static isEmpty(value: unknown): boolean {
    return !this.isNotEmpty(value)
  }

  /**
   * 如果value不为空返回value否则返回arg
   * @param value
   * @param arg
   */
  static isEmptyToVal<T, P>(value: T, arg: P): T | P {
    const result = this.isNotEmpty(value)
    return result ? value : arg
  }

  /**
   * 根据source更新target中的属性值
   * @param target
   * @param source
   */
  static updateVal(target: Record<string, unknown>, source: Record<string, any>): void {
    for (const key in target) {
      target[key] = source[key]
    }
  }

  /**
   * 拷贝
   * @param arg
   */
  static copy<T>(arg: T): T {
    return JSON.parse(JSON.stringify(arg))
  }

  /**
   * 深度拷贝
   * @param from
   */
  static deepCopy<T>(from: T): T {
    return this._deepCopy_(from)
  }

  /**
   * deepCopy的递归函数
   * @param from
   * @param to
   * @private
   */
  private static _deepCopy_(from: any, to?: any): any {
    if (Array.isArray(from)) {
      to = []
      from.forEach((item: unknown, index: number) => {
        to[index] = typeof from[index] === 'object' ? this._deepCopy_(from[index], to[index]) : from[index]
      })
    } else {
      if (!this.isObject(from)) {
        return from
      }
      to = {}
      for (const key in from) {
        to[key] = typeof from[key] === 'object' ? this._deepCopy_(from[key], to[key]) : from[key]
      }
    }
    return to
  }

  /**
   * 合并两个对象
   * @param target
   * @param source
   * @param option
   */
  static assignDeep<T, U>(target: T, source: U, option?: assignDeepOption): T & U {
    const _option_ = Object.assign({ deep: true, object: true, array: true }, option || {})
    this._assignDeep_(target, source, _option_)
    return <T & U>target
  }

  /**
   * assignDeep 的递归函数
   * @param target
   * @param source
   * @param option
   * @private
   */
  private static _assignDeep_(target: any, source: any, option: assignDeepOption): void {
    for (const key in source) {
      const val = source[key]
      if ((this.isObject(val) && this.isObject(target[key]) && option.object) || (Array.isArray(val) && Array.isArray(target[key]) && option.array)) {
        this._assignDeep_(target[key], val, option)
      } else {
        if (option.deep || target[key] !== undefined) {
          target[key] = val
        }
      }
    }
  }

  static isBoolean(arg: unknown) {
    return Object.prototype.toString.call(arg) === '[object Boolean]'
  }

  static isString(arg: unknown) {
    return Object.prototype.toString.call(arg) === '[object String]'
  }

  static isNumber(arg: unknown) {
    return Object.prototype.toString.call(arg) === '[object Number]'
  }

  static isObject(arg: unknown) {
    return Object.prototype.toString.call(arg) === '[object Object]'
  }

  static isDate(arg: unknown) {
    return Object.prototype.toString.call(arg) === '[object Date]'
  }

  static isArray(arg: unknown) {
    return Array.isArray(arg)
  }

  static isUndefined(arg: unknown) {
    return typeof arg === 'undefined'
  }

  static isPrototype(arg: unknown, type: string) {
    return Object.prototype.toString.call(arg) === '[object ' + type + ']'
  }
}

export class NumberUtil {
  /**
   * 保留最大小数位数
   * @param value
   * @param fractionDigits
   */
  static toFixed(value: string | number, fractionDigits: number): number {
    return Number(Number(value).toFixed(fractionDigits))
  }
}

export class StringUtil {
  /**
   * 替换某一段长度的字符串
   * @param str
   * @param start
   * @param end
   * @param rStr
   */
  static subReplace(str: string, start: number, len: number, rStr: string) {
    const s = str.substring(0, start)
    const e = str.substring(start + len, str.length)
    return s + rStr + e
  }
}
