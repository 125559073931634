interface RequiredConfig {
  required: boolean,
  message: string,
  trigger: string
}

interface Required {
  (message?: string, trigger?: string): RequiredConfig,

  blur(message?: string): RequiredConfig,

  change(message?: string): RequiredConfig
}

// 必填项
function requiredFn (message = '此项为必填项', trigger = 'blur'): RequiredConfig {
  return { required: true, message, trigger: trigger }
}

['blur', 'change'].forEach(trigger => {
  (<any>requiredFn)[trigger] = (message: string) => {
    return requiredFn(message, trigger)
  }
})
export const required: Required = <Required>requiredFn

interface ValidatorCallback {
  (error?: Error): void
}

export interface Validator {
  validator: { (rule: any, value: any, callback: ValidatorCallback): void },
  trigger: string
}

interface TagFn {
  (value: string): undefined | Error
}

// 手机号
export const phoneTag: TagFn = (value) => {
// 中国电信号码格式验证 手机段：
  // 133,149,153,173,177,180,181,189,191,199,1349,1410,1700,1701,1702
  const phoneTagDx = /(?:^(?:\+86)?1(?:33|49|53|7[37]|8[019]|9[19])\d{8}$)|(?:^(?:\+86)?1349\d{7}$)|(?:^(?:\+86)?1410\d{7}$)|(?:^(?:\+86)?170[0-2]\d{7}$)/

  // 中国联通号码格式验证 手机段：
  // 130,131,132,145,146,155,156,166,171,175,176,185,186,1704,1707,1708,1709
  const phoneTagLt = /(?:^(?:\+86)?1(?:3[0-2]|4[56]|5[56]|66|7[156]|8[56])\d{8}$)|(?:^(?:\+86)?170[47-9]\d{7}$)/

  // 中国移动号码格式验证 手机段：
  // 134,135,136,137,138,139,147,148,150,151,152,157,158,159,178,182,183,184,187,188,198,1440,1703,1705,1706
  const phoneTagYd = /(?:^(?:\+86)?1(?:3[4-9]|4[78]|5[0-27-9]|78|8[2-478]|98)\d{8}$)|(?:^(?:\+86)?1440\d{7}$)|(?:^(?:\+86)?170[356]\d{7}$)/
  if (value === '' || phoneTagDx.test(value) || phoneTagLt.test(value) || phoneTagYd.test(value)) {
    return undefined
  } else {
    return new Error('手机号格式不正确')
  }
}

export const phone: Validator = {
  validator: (rule, value, callback) => {
    callback(phoneTag(value))
  }, trigger: 'blur',
}

// 密码
export const passwordTag: TagFn = (value) => {
  const password = /^(?=.*[A-Za-z])(?=.*\d)[\w\-+.]{8,16}$/
  const passwordTwo = /^[\w\-+.]{8,16}$/
  if (value === '' || password.test(value)) {
    return undefined
  } else if (passwordTwo.test(value)) {
    return new Error('密码至少包含1位字母或数字')
  } else {
    return new Error('密码为8~16位字母、数字或”_ - + .“符号')
  }
}
export const password: Validator = {
  validator: (rule, value, callback) => {
    callback(passwordTag(value))
  }, trigger: 'blur',
}

// 昵称|显示名称|名称
export const displayNameTag: TagFn = (value) => {
  const displayName = /^[\u4e00-\u9fa5\w\d]{2,16}$/
  if (displayName.test(value)) {
    return undefined
  } else {
    return new Error('请输入2 ~ 16位汉字、字母或数字')
  }
}
export const displayName: Validator = {
  validator: (rule, value, callback) => {
    callback(displayNameTag(value))
  }, trigger: 'blur',
}

// 图形验证码
export const captchaTag: TagFn = (value) => {
  const position = /^[a-zA-Z\d]{4}$/
  if (position.test(value)) {
    return undefined
  } else {
    return new Error('验证码格式错误')
  }
}
export const captcha: Validator = {
  validator: (rule, value, callback) => {
    callback(captchaTag(value))
  }, trigger: 'blur',
}

// 短信验证码
export const SMSCodeTag: TagFn = (value) => {
  const position = /^[a-zA-Z\d]{6}$/
  if (position.test(value)) {
    return undefined
  } else {
    return new Error('验证码格式错误')
  }
}
export const SMSCode: Validator = {
  validator: (rule, value, callback) => {
    callback(SMSCodeTag(value))
  }, trigger: 'blur',
}

// 地理坐标
export const positionTag: TagFn = (value) => {
  const position = /^((-?180)|(-?1[0-7]\d)|(-?[1-9]\d)|(-?[1-9])|0)(\.\d+)?,((-?90)|(-?[1-8]\d)|(-?[1-9])|0)(\.\d+)?$/
  if (position.test(value)) {
    return undefined
  } else {
    return Error('坐标格式为(±180,±90)')
  }
}
export const position: Validator = {
  validator: (rule, value, callback) => {
    callback(positionTag(value))
  }, trigger: 'blur',
}
